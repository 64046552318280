import React, { useState, useEffect } from 'react';
import TableRow from '../TableRow/TableRow';
import PaginationFooter from '../PaginationFooter/PaginationFooter';

const LIST_SIZE = 10;

const Table = ({ group, data }) => {
    const [currentPages, setCurrentPages] = useState({
        feedback: 1,
        reviews: 1,
        leads: 1,
        agreements: 1
    });
    const [currentData, setCurrentData] = useState({});

    const totalItems = data.length;

    const createDataFormat = (currentPage) => {
        const startIndex = (currentPage - 1) * LIST_SIZE;
        const endIndex = startIndex + LIST_SIZE;
        return Object.keys(data)
            .slice(startIndex, endIndex)
            .reduce((acc, key) => {
                acc[key] = data[key];
                return acc;
            }, {});
    };

    useEffect(() => {
        setCurrentData(createDataFormat(currentPages[group]));
    }, [currentPages, group, data]);

    const handlePageChange = (group, page) => {
        setCurrentPages((prevPages) => ({
            ...prevPages,
            [group]: page,
        }));
    };

    const renderTableRows = () => (
        <div className='table-component-ctn'>
            {Object.entries(currentData).map(([key, item]) => (
                <div className="table-row-ctn" key={key}>
                    <TableRow
                        group={group}
                        data={item}
                    />
                </div>
            ))}
        </div>
    );

    const renderPaginationFooter = () => (
        <PaginationFooter
            currentPage={currentPages[group]}
            setCurrentPage={(page) => handlePageChange(group, page)}
            numberOfTotalPreviews={totalItems}
            numberOfPreviews={10}
            pageSize={10}
        />
    );

    const renderContent = () => (
        <>
            {renderTableRows()}
            {renderPaginationFooter()}
        </>
    );

    return (
        <div className='table-body'>
            {renderContent()}
        </div>
    );
};

export default Table;