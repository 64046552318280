import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SideChat from '../SideChat/SideChat';
import './style.scss'

const TableRow = ({ data, group }) => {
    const hotelId = localStorage.getItem('marie_hotel_id');
    const [isChecked, setIsChecked] = useState(JSON.parse(data.issued));
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const extractNumerator = (rating) => {
        if(!isNaN(rating)){
            return rating
        }
        if(typeof rating == 'string'){
            const regex = /^(\d+(\.\d+)?)\/(5|10)$/;
            const match = rating?.match(regex);
            if (match) {
                let numerator = parseFloat(match[1]);
                const denominator = parseInt(match[3], 10);
                if (denominator === 10) {
                    numerator = numerator / 2;
                }
                return numerator;
            }
            return 0;
        }else{
            return rating;
        }
    };

    useEffect(() => {
        setIsChecked(data.issued);
    }, [data.id, data.issued]);
    
    useEffect(() => {
        if (!isChatOpen) {
            setSelectedData({
                data
            });
        }
    }, [data.id]);

    const handleCheckboxChange = async(dynamoObject) => {
        const newIsChecked = !isChecked
        setIsChecked(newIsChecked);
        try{
            console.log(`/api/typeform/updateIssueStatus/${hotelId}/${newIsChecked}/${data.id}/${dynamoObject}`)
            await axios.put(`/api/typeform/updateIssueStatus/${hotelId}/${newIsChecked}/${data.id}/${dynamoObject}`);
        }catch(error){
            console.error("Failed to fetch the updated issue status");
        }
    };
    const truncateText = (text, maxLength) => {
        if (typeof text !== 'string' || !text) {
            return '';
        }
        return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
    };

    const toggleSideChat = () => {
        if (!isChatOpen) {
            setSelectedData({
                data
            });
        }
        setIsChatOpen(!isChatOpen);
    };

    const buttonStyle = {
        backgroundColor: isChecked ? "#AC5757" : "#6DA876"
    };
    const buttonLabel = (completed, notCompleted) => {
       const label = isChecked ? "Mark as " + notCompleted : "Mark as " + completed;
       return label; 
    }
    const guestFeedbackLabel = group === 'feedback' ? "Guest Feedback" : "Guest Review";

    const convertDateFormat = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    }

    const displayGroupData = () => {
        switch(group){
            case 'reviews':
                return(
                    <>
                        <div className='table-data-point' id='feedback-guest' onClick={toggleSideChat}>{truncateText(`${data.firstName} ${data.lastName}`, 15)}</div>
                        <div className='table-data-point' id='feedback-rating' onClick={toggleSideChat}>{extractNumerator(data.rating)}</div>
                        <div className='table-data-point' id='feedback-feedback' onClick={toggleSideChat}>{truncateText(data.feedback, 60)}</div>
                        <div className='table-data-point' id='feedback-timestamp' onClick={toggleSideChat}>{data.date}</div>
                        <div className={`table-data-point ${isChecked ? 'issued' : 'not-issued'}`} id='feedback-issued' onClick={toggleSideChat}>
                            {isChecked ? 'Issued' : 'Not Issued'}
                        </div>
                        <SideChat 
                            data={selectedData}
                            constructor={'reviews'}
                            id={data.id}
                            isOpen={isChatOpen} 
                            toggleSideChat={toggleSideChat} 
                            guestMessageLabel={guestFeedbackLabel}
                            buttonStyle={buttonStyle}
                        />
                    </>
                )
            case 'feedback':
                return(
                    <>
                        <div className='table-data-point'>
                            <div className="reviews-check-box-container">
                                <input type="checkbox" checked={isChecked} onChange={() => handleCheckboxChange("TypeFormData")}/>
                            </div>
                        </div>
                        <div className='table-data-point' id='feedback-guest' onClick={toggleSideChat}>{truncateText(`${data.first_name} ${data.last_name}`, 15)}</div>
                        <div className='table-data-point' id='questions-container'>
                            {data?.questions?.map((questionObj, index) => (
                                <div key={index} className='question-item' id={`question-${index}`} onClick={toggleSideChat}>
                                    <div>{truncateText(`${questionObj.response}`, 60)}</div>
                                </div>
                            ))}
                        </div>                        
                        <div className='table-data-point' id='feedback-timestamp' onClick={toggleSideChat}>{data.timestamp}</div>
                        <div className={`table-data-point ${isChecked ? 'issued' : 'not-issued'}`} id='feedback-issued' onClick={toggleSideChat}>
                            {isChecked ? 'Issued' : 'Not Issued'}
                        </div>
                        <SideChat 
                            data={selectedData}
                            constructor={'feedback'}
                            id={data.id}
                            isOpen={isChatOpen} 
                            toggleSideChat={toggleSideChat} 
                            guestMessageLabel={guestFeedbackLabel}
                            buttonFunction={() => handleCheckboxChange("TypeFormData")}
                            buttonStyle={buttonStyle}
                            buttonLabel={() => buttonLabel('Issued', 'Not Issued')}
                        />
                    </>
                )
            case 'leads':
                return(
                    <>
                        <div className='table-data-point'>
                            <div className="reviews-check-box-container">
                                <input type="checkbox" checked={isChecked} onChange={() => handleCheckboxChange("LeadsForm")}/>
                            </div>
                        </div>
                        <div className='table-data-point' id='feedback-guest' onClick={toggleSideChat}>{truncateText(`${data?.first_name} ${data?.last_name}`, 15)}</div>
                        <div className='table-data-point' id='leads-timestamp' onClick={toggleSideChat}>{convertDateFormat(data?.timestamp)}</div>
                        <div className={`table-data-point ${isChecked ? 'issued' : 'not-issued'}`} id='leads-issued' onClick={toggleSideChat}>
                            {isChecked ? 'Contacted' : 'Not Contacted'}
                        </div>
                        <SideChat 
                            data={selectedData}
                            constructor={'leads'}
                            id={data.id}
                            isOpen={isChatOpen} 
                            toggleSideChat={toggleSideChat} 
                            guestMessageLabel={guestFeedbackLabel}
                            buttonFunction={() => handleCheckboxChange("LeadsForm")}
                            buttonStyle={buttonStyle}
                            buttonLabel={() => buttonLabel('Contacted', 'Not Contacted')}
                        />
                    </>
                )
            case 'agreements':
                return(
                    <>
                        <div className='table-data-point'>
                            <div className="reviews-check-box-container">
                                <input type="checkbox" checked={isChecked} onChange={() => handleCheckboxChange("AgreementForms")}/>
                            </div>
                        </div>
                        <div className='table-data-point' id='feedback-guest' onClick={toggleSideChat}>{truncateText(`${data &&  data.questions && data.questions[5] && data?.questions[5]?.response ?  data?.questions[5]?.response : ''}`, 15)}</div>
                        <div className='table-data-point' id='leads-timestamp' onClick={toggleSideChat}>{convertDateFormat(data?.timestamp)}</div>
                        <div className={`table-data-point ${isChecked ? 'issued' : 'not-issued'}`} id='leads-issued' onClick={toggleSideChat}>
                            {isChecked ? 'Cleared' : 'Not Cleared'}
                        </div>
                        <SideChat 
                            data={selectedData}
                            constructor={'leads'}
                            id={data.id}
                            isOpen={isChatOpen} 
                            toggleSideChat={toggleSideChat} 
                            guestMessageLabel={guestFeedbackLabel}
                            buttonFunction={() => handleCheckboxChange("LeadsForm")}
                            buttonStyle={buttonStyle}
                            buttonLabel={() => buttonLabel('Contacted', 'Not Contacted')}
                        />
                    </>
                )
        }

    }
    return (
        <div className='table-row'>
            {displayGroupData()}
        </div>
    );
};

export default TableRow;